<template>
  <div>
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      spinner-variant="primary"
    >
      <b-card>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search requests"
              type="text"
            />
            <b-button
              v-b-modal.addRequestModal
              variant="primary"
              @click="title = ''; description = ''; type = ''; instructions = ''; show_instructions = true; update = false;"
            >
              <feather-icon icon="PlusIcon" />
              Add Service Request
            </b-button>
            <b-button
              v-b-tooltip.hover
              class="ml-2"
              title="Delete Rejected Requests"
              variant="outline-danger"
              @click="deleteAllRejectedRequests"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
            <b-form-checkbox
              v-model="hideRejected"
              class="d-flex align-items-center mt-1 justify-content-start"
            >
              {{ hideRejected ? 'Show Rejected Requests' : 'Hide Rejected Requests' }}
            </b-form-checkbox>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="filteredRows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'title'"
            >
              {{ props.row.partnerservices.title }}
            </span>
            <span
              v-else-if="props.column.field === 'type'"
            >
              {{ props.row.partnerservices.type }}
            </span>
            <span
              v-else-if="props.column.field === 'status'"
            >
              <b-badge
                style="text-transform: capitalize"
                :variant="`${props.row.status === 'approved' ? 'primary' : (props.row.status === 'unchecked' ? 'light-primary' : 'light-danger')}`"
              >{{ props.row.status }}</b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="d-flex align-items-center"
            >
              <b-button
                size="sm"
                variant="primary"
                class="mr-1"
                :disabled="!(!props.row.partnerservices.show_instructions || props.row.status === 'approved')"
                @click="title = props.row.partnerservices.title; description = props.row.partnerservices.description; type = props.row.partnerservices.type; instructions = props.row.partnerservices.instructions; show_instructions = props.row.partnerservices.show_instructions; affiliate_link = props.row.partnerservices.affiliate_link; $bvModal.show('viewRequestModal')"
              >
                View Instructions
              </b-button>
              <b-button
                v-if="props.row.status === 'unchecked'"
                size="sm"
                variant="flat-danger"
                @click="deleteService(props.row.id)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['25','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
        <b-modal
          id="addRequestModal"
          no-close-on-backdrop
          ok-only
          size="xl"
          title="Request Service"
          ok-title="Ok"
        >
          <b-row>
            <b-col md="12">
              <b-row class="px-1 mb-2">
                <b-col
                  :class="tab===1?'selected-top-tab':'deselected-top-tab'"
                  @click="tab=1"
                >
                  <span>Starworks</span>
                </b-col>
                <b-col
                  :class="tab===2?'selected-top-tab':'deselected-top-tab'"
                  @click="tab=2"
                >
                  <span>Incubator</span>
                </b-col>
              </b-row>
              <div v-if="tab===1">
                <vue-good-table
                  :columns="service_columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLengthModal
                  }"
                  :rows="services"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'action'"
                      class="d-flex align-items-center"
                    >
                      <b-button
                        size="sm"
                        variant="primary"
                        class="d-flex align-items-center mr-1"
                      >
                        Request Service
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="pageLengthModal"
                          :options="['3','5','10']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="pageLengthModal"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
              <div v-if="tab===2">
                <vue-good-table
                  :columns="incubator_service_columns"
                  :pagination-options="{
                    enabled: true,
                    perPage:pageLengthModal
                  }"
                  :rows="incubator_services"
                  :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                >
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span
                      v-if="props.column.field === 'action'"
                      class="d-flex align-items-center"
                    >
                      <b-button
                        size="sm"
                        variant="primary"
                        class="d-flex align-items-center mr-1"
                        :disabled="requests.map(r => r.partnerservices.id).includes(props.row.id)"
                        @click="requestService(props.row.id)"
                      >
                        {{ requests.map(r => parseInt(r.partnerservices.id)).includes(props.row.id) ? 'Requested' : 'Request Service' }}
                      </b-button>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                          Showing 1 to
                        </span>
                        <b-form-select
                          v-model="pageLengthModal"
                          :options="['5','10','15']"
                          class="mx-1"
                          @input="(value)=>props.perPageChanged({currentPerPage:value})"
                        />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                      </div>
                      <div>
                        <b-pagination
                          :per-page="pageLengthModal"
                          :total-rows="props.total"
                          :value="1"
                          align="right"
                          class="mt-1 mb-0"
                          first-number
                          last-number
                          next-class="next-item"
                          prev-class="prev-item"
                          @input="(value)=>props.pageChanged({currentPage:value})"
                        >
                          <template #prev-text>
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </template>
                          <template #next-text>
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </div>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal
          id="viewRequestModal"
          no-close-on-backdrop
          ok-only
          size="md"
          title="View Partner Service"
          ok-title="Ok"
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Service Title"
                label-for="Service Title"
                disabled
              >
                <b-form-input
                  id="title"
                  v-model="title"
                  placeholder="Service Title"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="Description"
              >
                <b-form-textarea
                  id="description"
                  v-model="description"
                  placeholder="Description"
                  type="text"
                  rows="2"
                  max-rows="4"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Type"
                label-for="Type"
              >
                <v-select
                  v-model="type"
                  class="text-capitalize"
                  :options="['Free Credits','Discount','Consultation','Free Service/Product','Other']"
                  placeholder="Choose Type"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Affiliate Link"
                label-for="Affiliate Link"
                disabled
              >
                <b-form-input
                  id="affiliate_link"
                  v-model="affiliate_link"
                  placeholder="Affiliate Link"
                  type="text"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Instructions"
                label-for="Instructions"
              >
                <b-form-textarea
                  id="instructions"
                  v-model="instructions"
                  placeholder="Instructions"
                  type="text"
                  rows="2"
                  max-rows="4"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-modal>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BCard,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import { getUserData } from '@/utils/auth'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BFormInput,
    BCard,
    BFormGroup,
    BFormCheckbox,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BFormTextarea,
    BBadge,
    vSelect,
  },
  data() {
    return {
      searchTerm: '',
      selectedRow: 0,
      pageLength: 25,
      pageLengthModal: 5,
      title: '',
      description: '',
      instructions: '',
      type: '',
      tab: 1,
      show_instructions: true,
      columns: [
        {
          label: 'Service Title',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Created Date',
          field(row) {
            return new Date(row.created_at)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                timeZone: getUserData().timezone.abbr,
              })
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      service_columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      incubator_service_columns: [
        {
          label: 'Title',
          field: 'title',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Incubator',
          field(row) {
            return row.users_organizationtable.title
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      requester: null,
      requests: [],
      hideRejected: true,
      services: [],
      incubator_services: [],
      mutationLoading: false,
      request: {
        title: '',
        description: '',
        priority: '',
      },
      assignee: null,
      associations: [
        {
          id: '0',
          label: 'Startups',
          children: [
            {
              id: '0-0',
              label: 'Incubatee',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-1',
              label: 'Graduate',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-2',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
            {
              id: '0-3',
              label: 'Pre-Incubatee',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '1',
          label: 'Students',
          children: [
            {
              id: '1-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '1-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '2',
          label: 'Mentors',
          children: [
            {
              id: '2-0',
              label: 'In-house',
              children: [],
              isDisabled: true,
            },
            {
              id: '2-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
        {
          id: '3',
          label: 'Partners',
          children: [
            {
              id: '3-0',
              label: 'In-House',
              children: [],
              isDisabled: true,
            },
            {
              id: '3-1',
              label: 'Network',
              children: [],
              isDisabled: true,
            },
          ],
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        assigned: 'light-secondary',
        unassigned: 'light-warning',
        unchecked: 'light-secondary',
        'in-process': 'light-primary',
        resolved: 'light-success',
        closed: 'light-danger',
        low: 'light-success',
        medium: 'light-info',
        high: 'light-warning',
        urgent: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      let { requests } = this
      if (this.hideRejected) {
        requests = requests.filter(r => r.status !== 'rejected')
      }
      return requests
    },
  },
  methods: {
    deleteAllRejectedRequests() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation deleteAllRejectedRequests {
            update_partnerservices_request(
              where: {
                _and: [
                  {status: {_eq: "rejected"}},
                  {startup_id: {_eq: ${getUserData().associatedOrgDetails[0].organization_id}}}
                ]
              },
              _set: {is_deleted: true}
            ) {
              affected_rows
            }
          }
        `,
      })
        .then(() => {
          this.showAlert('All Rejected deleted successfully', 'success')
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
    },
    requestService(id) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation addRequest {
              insert_partnerservices_request_one (object: {startup_id: "${getUserData().associatedOrgDetails[0].organization_id}", partnerservices_id: ${id}}){
                id
              }
          },
        `,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Service request added successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
          this.$bvModal.hide('addRequestModal')
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding request',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    deleteService(id) {
      // msgOkcofirm
      this.$bvModal.msgBoxConfirm(
        'Are you sure you want to delete this service request?',
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete File',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation MyMutation {
                      update_partnerservices_request_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.requests.refetch()
                },
              },
            )
          }
        })
    },
    updateRequestStatus(requestId, status) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestStatus($requestId: Int!, $status: String!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {status: $status}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          status,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request status updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating request status',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    updateRequestAssignee(requestId, assigneeId) {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
          mutation updateRequestAssignee($requestId: Int!, $assigneeId: Int!) {
            update_requesttable_by_pk(pk_columns: {requesttable_id: $requestId}, _set: {assignee_id: $assigneeId, assignee_status: "unchecked"}) {
              requesttable_id
            }
          },
        `,
        variables: {
          requestId,
          assigneeId,
        },
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Request assigned successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.mutationLoading = false
          this.assignee = null
          this.$apollo.queries.requests.refetch()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating assignee',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
          this.assignee = null
        })
    },
  },
  apollo: {
    requests: {
      query() {
        return gql`query requests {
          partnerservices_request(where: {_or: {startup_id: {_eq: ${getUserData().associatedOrgDetails[0].organization_id}}}}) {
            id
            rating
            remarks
            status
            created_at
            startup_id
            partnerservices {
              id
              title
              type
              instructions
              affiliate_link
              show_instructions
              is_default
              users_organizationtable {
                title
              }
            }
          }
        }`
      },
      update: data => data.partnerservices_request,
    },
    services: {
      query() {
        return gql`query services {
          partnerservices(where: {is_default: {_eq: true}}) {
            description
            id
            title
            type
          }
        }`
      },
      update: data => data.partnerservices,
    },
    incubator_services: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`query incubator_services {
          partnerservices(where: {_and: {users_organizationtable: {domain: {_regex: "${subdomain}"}, usersAssociationtablesByAssociatedOrg: {organization_id: {_eq: ${getUserData().associatedOrgDetails[0].organization_id}}, role: {_neq: "startup"}}}}}) {
            description
            id
            title
            type
            users_organizationtable {
              id
              title
            }
          }
        }`
      },
      update: data => data.partnerservices,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

<style>
.selected-top-tab{
  background-color: #7367F0;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
.deselected-top-tab{
  background-color: #F1F1F3;
  color: #000;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bolder;
  cursor: pointer;
}
</style>
